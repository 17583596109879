import React from "react";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import { myTheme } from "./services/theme";
import { ConfirmationModalProvider } from "./components/ConfirmationModal/ConfirmationModalContext";
import { OrderStatusProvider } from "./components/OrderStatusContext/OrderStatusContext";
import { StateDefinitionsProvider } from "./components/StateDefinitionsContext/StateDefinitionsContext";
import { WaitTimeProvider } from "./components/WaitTimeDisplay/WaitTimeContext";
import { ConnectionStatusProvider } from "./components/ConnectionStatusSnackbar/ConnectionStatusContext";
import { StoreProvider } from "./components/StoreContext/StoreContext";
import ActiveOrdersDisplay from "./scenes/ActiveOrdersDisplay";
import AppContainer from "./components/AppContainer";
import OneTimeCodeSetup from "./scenes/OneTimeCodeSetup";
import OrderHistoryDisplay from "./scenes/OrderHistoryDisplay";
import PrivateRoute from "./components/PrivateRoute";
import UrlLinker from "./components/UrlLinker";
import usePollAppVersion from "./hooks/usePollAppVersion";
import useLockScalingForNativeApp from "./hooks/useLockScalingForNativeApp";
import AutoRefresh from "./components/AutoRefresh";
import SsoHandler from "./scenes/SsoHandler";
import { AriaHideMainContentProvider } from "./components/AriaHideMainContentContext/AriaHideMainContentContext";

/*
  This app was originally designed as a simple way to visualise tempo notifications, and so I started off with just a single context provider
  for order status. As the App grew and became more complex I added more context providers as needed, but it is now a little complicated due to the
  amount of nested context providers. If you want it could make sense to update the state management to a more centrally managed solution,
  however I have found that the context providers do generally make it fairly easy to use in development as you can just import whichever context hook
  you want and then repeat calls when different components are loaded can be avoided.
 */
function App() {
  const shouldRefresh = usePollAppVersion();
  useLockScalingForNativeApp();
  return (
    <ThemeProvider theme={myTheme}>
      <ConnectionStatusProvider>
        <AriaHideMainContentProvider>
          <AutoRefresh shouldRefresh={shouldRefresh} />
          <StateDefinitionsProvider>
            <WaitTimeProvider>
              <OrderStatusProvider>
                <StoreProvider>
                  <ConfirmationModalProvider>
                    <AppContainer>
                      <Router>
                        <PrivateRoute
                          path="/"
                          component={<ActiveOrdersDisplay />}
                        />
                        <PrivateRoute
                          path="/active"
                          component={<ActiveOrdersDisplay />}
                        />
                        <PrivateRoute
                          path="/history"
                          component={<OrderHistoryDisplay />}
                        />
                        <SsoHandler path="/:region/sso/:urlCode" />
                        <UrlLinker path="/:region/:urlCode" />
                        <OneTimeCodeSetup path="/:region" />
                        <OneTimeCodeSetup path="/register" />
                      </Router>
                    </AppContainer>
                  </ConfirmationModalProvider>
                </StoreProvider>
              </OrderStatusProvider>
            </WaitTimeProvider>
          </StateDefinitionsProvider>
        </AriaHideMainContentProvider>
      </ConnectionStatusProvider>
    </ThemeProvider>
  );
}

export default App;
