import { useCallback, useState } from "react";
import {
  mockStateDefinitions,
  mockStateDefinitionsResponse,
} from "../data/mockStateDefinitions";
import { StateDefinitionConfig } from "../types/StateDefinitionType";
import * as api from "../services/api";
import * as errorFactory from "../services/errorFactory";
import * as persistenceManager from "../services/persistenceManager";

export default () => {
  const [stateDefinitions, setStateDefinitions] = useState<
    undefined | StateDefinitionConfig
  >();
  const [stateDefinitionHash, setStateDefinitionHash] = useState<
    undefined | string
  >();
  const [stateDefinitionsLoaded, setStateDefinitionsLoaded] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const updateStateDefinitions = useCallback(async () => {
    const token = persistenceManager.getAuthToken();
    const region = persistenceManager.getRegion();

    if (token && region) {
      try {
        // See hello API swagger for a full description of all of the state configuration properties.
        const stateDefinitionsResponse =
          process.env.REACT_APP_USE_MOCK_VALUES === "true"
            ? mockStateDefinitionsResponse
            : await api.getStateDefinitions(token as string, region);
        setStateDefinitionHash(stateDefinitionsResponse.stateHash);
        setStateDefinitions(stateDefinitionsResponse.stateDefinitions);
      } catch (error) {
        console.log(
          "Failed to get state definitions. Using fallback definitions"
        );
        setStateDefinitions(mockStateDefinitions);
      }
      setStateDefinitionsLoaded(true);
    } else {
      if (!token) {
        setError(errorFactory.createNoTokenError());
      } else if (!region) {
        setError(errorFactory.createNoRegionError());
      }
    }
  }, []);

  return {
    stateDefinitions,
    updateStateDefinitions,
    stateDefinitionHash,
    stateDefinitionsLoaded,
    error,
  };
};
