import React, { useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  useOrderStatus,
  useStateDefinitions,
} from "../../hooks/useSharedContext";
import LoadingPage from "../LoadingPage";
import { getSsoLoginPage } from "../../services/persistenceManager";

interface PrivateRouteProps extends RouteComponentProps {
  component: React.ReactElement;
}

export default (props: PrivateRouteProps) => {
  const { loginSuccessful, loadingFirstRequest } = useOrderStatus();
  const { stateDefinitionsLoaded } = useStateDefinitions();

  // On first load wait to see if the user is able to successfully login to Hello Screens. If so then go directly to that page.
  // If the first request to Hello API fails then redirect to the linking page
  useEffect(() => {
    if (!loginSuccessful && !loadingFirstRequest) {
      console.log("not logged in");
      const ssoLoginUrl = getSsoLoginPage();
      if (ssoLoginUrl) {
        window.location.href = ssoLoginUrl;
      } else {
        navigate("/register");
      }
    }
  }, [loadingFirstRequest, loginSuccessful]);

  if (loginSuccessful && stateDefinitionsLoaded) {
    return props.component;
  }

  return <LoadingPage />;
};
